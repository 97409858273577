import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import Services from "../components/Services"
import SampleWorks from "../components/SampleWorks"
import Testimonials from "../components/Testimonials"
import Plan from "../components/Plan"
import ContentImage from "../components/ContentImage"

const logo = [
  {
    name: 'Crafters',
    image: require('../assets/svg/brands-crafters-companion-logo.svg')
  }, {
    name: 'Cyber Talks',
    image: require('../assets/svg/brands-cybertalks-logo.svg')
  }, {
    name: 'Go Page',
    image: require('../assets/svg/brands-gopage-logo.svg')
  }, {
    name: 'Life Grip',
    image: require('../assets/svg/brands-lifegrip-logo.svg')
  }, {
    name: 'On Target',
    image: require('../assets/svg/brands-ontarget-logo.svg')
  }
]

const logoFeat = [
  {
    name: 'Gatsby',
    image: require('../assets/svg/gatsby-1.svg'),
    link: 'https://www.gatsbyjs.org/showcase/fullstackhq.com',
    activeLink: true
  }, {
    name: 'Awards',
    image: require('../assets/svg/brands-awwwards-logo.svg'),
    link: 'https://www.awwwards.com/sites/psd-wizard-1',
    activeLink: true
  }, {
    name: 'Css Design Awards',
    image: require('../assets/svg/brands-cssdesign-logo.svg'),
    link: 'https://www.cssdesignawards.com/sites/psd-wizard/32529/',
    activeLink: true
  }, {
    name: 'JAMStack',
    image: require('../assets/svg/brands-jamstack-logo.svg'),
    link: 'https://jamstack.org/examples/',
    activeLink: true
  }, {
    name: 'React Static',
    image: require('../assets/svg/brands-react-logo.svg'),
    link: 'https://github.com/react-static/react-static#sites-built-with-react-static',
    activeLink: true
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/web.png'),
    desc: 'Web & mobile design'
  }, {
    image: require('../assets/images/social.png'),
    desc: 'Social media, emails and ads graphics'
  }, {
    image: require('../assets/images/branding.png'),
    desc: 'Logos & branding'
  }, {
    image: require('../assets/images/illustrations.png'),
    desc: 'Art & illustrations'
  }, {
    image: require('../assets/images/presentations.png'),
    desc: 'Presentations, flyers, brochures and invitations'
  }, {
    image: require('../assets/images/posters.png'),
    desc: 'Posters, billboards, banners any business graphics'
  }
]

const workLinks = [
  {
    image: require('../assets/images/works-lodg-site.png'),
    link: 'https://www.figma.com/design/vrrmYNpbBE2BKdtyfkLNYk/Lodg-Web-Design-1?node-id=12077-953&t=mf2XxluLbRXsFFrC-1',
  },
  {
    image: require('../assets/images/works-trualert-site.png'),
    link: 'https://www.figma.com/proto/RNRGv2iaHjpJNnMuhxP0nZ/Tru-Alert-Web-Design-2?node-id=1-675&t=fxzNngBuZdFtzGlE-1',
  },
  {
    image: require('../assets/images/works-cafe86-mobile.png'),
    link: 'https://www.figma.com/design/ZMPrKYBcDfZAxvGdvXhoNt/Cafe86-Mobile-Design-1?node-id=3-13&t=D76jlMtAhijLzZYB-1',
  },
  {
    image: require('../assets/images/works-punchey-site.png'),
    link: 'https://www.figma.com/proto/1e12Soqrj0Fg7Jc8As6ZBy/Punchey-Web-Design-3?node-id=456-2780&t=Ko7Oz43WuoMlRBHB-1',
  },
  {
    image: require('../assets/images/works-lodg-dashboard.png'),
    link: 'https://www.figma.com/design/vrrmYNpbBE2BKdtyfkLNYk/Lodg-Web-Design-1?node-id=12077-953&t=mf2XxluLbRXsFFrC-1',
  },
  {
    image: require('../assets/images/works-herren-site.png'),
    link: 'https://www.figma.com/design/poXCrC7VK5boWGbdYKvzPO/Herrensauna-Web-Design-4?node-id=7007-4035&t=N7SXq7MOgA5hqRe5-1',
  },
  {
    image: require('../assets/images/works-spotigy-site.png'),
    link: 'https://drive.google.com/file/d/1Qdxd3qTqySZOub_ieg69VvWdff_oydO5/view?usp=sharing',
  },
  {
    image: require('../assets/images/works-snuzi-site.png'),
    link: 'https://drive.google.com/file/d/1duWMmxkkDhYnLl45hSI_rsVetGtErUiq/view?usp=sharing',
  },
  {
    image: require('../assets/images/works-lifegrip-site.png'),
    link: 'https://drive.google.com/file/d/1k1prqMca5PmdCOpam6yZC7D2hQNKHXuN/view?usp=sharing',
  }, 
  {
    image: require('../assets/images/works-bsl-site.png'),
    link: 'https://dribbble.com/shots/7064050-Black-Sheep-Labs-Design/attachments/64988?mode=media',
  }, 
  {
    image: require('../assets/images/works-psdwizard.jpg'),
    link: 'https://dribbble.com/shots/7025149/attachments/25180?mode=media',
  }, {
    image: require('../assets/images/works-gopage.jpg'),
    link: 'https://dribbble.com/shots/7012592/attachments/16900?mode=media',
  }, {
    image: require('../assets/images/works-fshqposters.jpg'),
    link: 'https://dribbble.com/shots/7012676/attachments/16951?mode=media',
  }, {
    image: require('../assets/images/works-digix.jpg'),
    link: 'https://dribbble.com/shots/6993454-Digix-Mobile-Design/attachments/7864?mode=media',
  }, {
    image: require('../assets/images/works-ontarget.jpg'),
    link: 'https://dribbble.com/shots/6993096-On-Target-Web-Design/attachments/7677?mode=media',
  },  {
    image: require('../assets/images/works-bcremit.jpg'),
    link: 'https://dribbble.com/shots/7031292/attachments/31462?mode=media',
  }, {
    image: require('../assets/images/works-cybertalks.jpg'),
    link: 'https://dribbble.com/shots/7025243-CyberTalks-Web-Design-and-Development/attachments/25269?mode=media',
  },  {
    image: require('../assets/images/works-futurehitfactory.jpg'),
    link: 'https://dribbble.com/shots/6993258-FutureHitFactory-Web-Design/attachments/16096?mode=media',
  },  {
    image: require('../assets/images/works-torqen.jpg'),
    link: 'https://dribbble.com/shots/7025287-Torqen-Web-Design-and-Development/attachments/25317?mode=media',
  }, {
    image: require('../assets/images/works-fs-biz-card.jpg'),
    link: 'https://dribbble.com/shots/7058805/attachments/59684?mode=media',
  }, {
    image: require('../assets/images/works-rawmate.jpg'),
    link: 'https://dribbble.com/shots/7058747-RawMate-Web-Design-Development/attachments/59620?mode=media',
  }
]

const testimonyList = [
  {
    text: "Wow. U guys are simply amazing. This design is stunnning. Your design team rocks @Peng! They nailed it first go. Quite impressive",
    image: require('../assets/images/eddie-machaalani.png'),
    name: 'Eddie Machaalani',
    position: 'BigCommerce, Co-founder'
  }, {
    text: "I have consistently record sales over the last couple months. So I just wanted to THANK YOU and tell you that do have done a great work for me. Thank you!!!",
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'AAR Auto Glass, Owner'
  }, {
    text: "We love it, Client Loves it. Great work Dante! Keep them coming. Thanks for the hard work!",
    image: require('../assets/images/alan-thomas.png'),
    name: 'Alan Thomas',
    position: 'CloudCom USA, Project Manager'
  }
]

const plansInfo = [
  {
    image: require('../assets/images/plans-img-3.png'),
    title: 'Professional',
    price: '1800',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '140-hour credit balance',
        description:
        <p>
         You will be given 140-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'normal',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  },
  {
    image: require('../assets/images/plans-img-2.png'),
    title: 'Starter',
    price: '1200',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '80-hour credit balance',
        description:
        <p>
         You will be given 80-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'outline',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  },
  {
    image: require('../assets/images/plans-img-1.png'),
    title: 'Basic',
    price: '720',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '40-hour credit balance',
        description:
        <p>
         You will be given 40-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'outline',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  }
]

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: true,
  title: 'Design at scale for ambitious brands',
  desc: `Design subscription service for agencies, startups, and entrepreneurs.`,
  image: require('../assets/images/banner-design.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function design() {
  return (
    <>
      <OgTags
        ogTitle="Design at scale for ambitious brands by Fullstack HQ"
        ogDescription="Design subscription service for agencies, startups, and entrepreneurs."
        ogUrl="https://fullstackhq.com/design"
      />
      <Header />
      <main className="design initialized-header">
        <ContentImage content={ cta }  />
        <Brands
          text="Some of the brands we work with..."
          brands={ logo }
        />
        <Services
          heading="What we do"
          services= { servicesInfo }
          text="*Excludes videos, animations, search engine optimization, 3D/CAD, copywriting, business analysis, social media management."
        />
        <SampleWorks
          works={ workLinks }
        />
        <Testimonials
          title="Loved by world-class entrepreneurs & tech companies"
          testimony={ testimonyList }
        />
        <Brands
          text="We've been featured on"
          brands={ logoFeat }
        />
        <Plan
          heading="No upfront cost, 7-day trial work"
          content={plansInfo}
        />
      </main>
      <Footer />
    </>
  )
}

export default design

