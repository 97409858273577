import React from "react"

function Brands(props) {
  const {
    brands,
    text,
    type,
  } = props


  return (
    <div className="section-brands">
      <div className="container">
        <p className="p-lg text-center">{ text }</p>
        <div className={type !== 'partners' ? "brands-logo-holder" : "partners-logo-holder"}>
          { brands.map((logo, i) => {
            return (
              <div className="image-holder" key={ i }>
                { logo.link ?
                  <a href={logo.link} target="_blank" rel="noopener noreferrer nofollow">
                    <img src={ logo.image } className={ logo.type !== 'partner' ? "img-logo img-fluid" : "img-fluid partner-logo"} alt={ logo.name }/>
                  </a> :
                  <img src={ logo.image } className={ logo.type !== 'partner' ? "img-logo img-fluid" : "img-fluid partner-logo"} alt={ logo.name }/>
                }
              </div>
            )
          }) }
        </div>
      </div>
    </div>
  )
}

export default Brands
