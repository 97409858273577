import React from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'
import FormsContact from '../components/Forms'

function ContentImage(props) {
	function handleClick() {
		if (typeof window !== 'undefined') {
			if (window.Beacon) {
				window.Beacon('toggle')
			} else {
				alert('Chat helper connection timeout. Please refresh and try again.')
			}
		}
	}

	const { content } = props

	return (
		<div
			className={clsx(content.hasBackground ? 'section-cta bg-light' : 'section-cta', content.customClass, {
				'no-image': content.noImageCTA,
				'style-a': content.styleA,
				'style-b': content.styleB
			})}
		>
			<div className="container">
				<div
					className={clsx('column-holder', {
						'column-content-full': content.isFullContent,
						'column-image-primary': content.imagePrimary
					})}
				>
					<div className="column-content content-centered">
						<h2 className="content-title">{content.title}</h2>
						{content.subtitle ? <h3 className="content-subtitle">{content.subtitle}</h3> : ''}
						<p className="content-text p-md"> {content.desc} </p>
						{content.hasButton === true ? (
							<div className="btn-holder">
								{content.buttons.map((button, i) => {
									return (
										<div className="cta-btn" key={i}>
											{button.beacon === true ? (
												<button
													onClick={handleClick}
													className={
														button.type === 'normal' ? (
															'btn btn-blue'
														) : (
															'btn btn-outline-blue'
														)
													}
												>
													{button.text}
												</button>
											) : button.external === true ? (
												<a
													href={button.link}
													target={button.nextTab === false ? '_self' : '_blank'}
													rel="noopener noreferrer nofollow"
													className={
														button.type === 'normal' ? (
															'btn btn-blue'
														) : (
															'btn btn-outline-blue'
														)
													}
												>
													{button.text}{' '}
												</a>
											) : (
												<Link
													to={button.link}
													className={
														button.type === 'normal' ? (
															'btn btn-blue'
														) : (
															'btn btn-outline-blue'
														)
													}
												>
													{button.text}{' '}
												</Link>
											)}
										</div>
									)
								})}
							</div>
						) : (
							''
						)}
						{content.hasImage === true ? (
							<img className="logo-img" src={content.logoImage} alt={content.logoName} />
						) : (
							''
						)}
						{content.hasForm === true ? <FormsContact submitValue={content.submitValue} url={content.formURL} /> : ''}
					</div>
					<div
						className={clsx('column-image', {
							'column-image-none': content.noImage
						})}
					>
						<img className="mascot-img" src={content.image} alt="column placeholder" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContentImage
