import React from 'react'
import { Helmet } from "react-helmet"

import PropTypes from 'prop-types'

export class OgTags extends React.Component {
  render() {
    return (
      <Helmet>
        {/* <meta itemprop="name" content="PSD Wizard | Best PSD to HTML Conversion Service Provider" /> */}
        <title>{this.props.ogTitle}</title>
        <meta name="description" content={this.props.ogDescription}/>
        <meta itemprop="name" content={this.props.ogTitle} />
        <meta itemprop="description" content={this.props.ogDescription} />
        <meta itemprop="image" content={this.props.ogImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@psdwizard" />
        <meta name="twitter:creator" content="@psdwizard" />
        <meta name="twitter:title" content={this.props.ogTitle} />
        <meta name="twitter:description" content={this.props.ogDescription} />
        <meta name="twitter:image" content={this.props.ogImage} />

        <meta property="og:title" content={this.props.ogTitle} />
        <meta property="og:url" content={this.props.ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={this.props.ogDescription} />
        <meta property="og:image" content={this.props.ogImage} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:url" content={this.props.ogImage} />
        <meta property="og:image:secure_url" content={this.props.ogImage} />
        <meta property="og:image:alt" content="Fullstack Team as a Service" />
      </Helmet>
    )
  }
}

OgTags.defaultProps = {
  ogTitle: 'Fullstack HQ | Fullstack-Team-as-a-Service for Your Business',
  ogDescription: "Your secret weapon, white label development team. Like hiring an in-house team of designers & developers - at a fraction of the cost.",
  ogImage: 'https://fullstackhq.com/opengraph.jpg'
}

OgTags.propTypes = {
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string.isRequired
}
