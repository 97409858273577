import React, {useState} from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

function Forms(props) {

  const inputform = props

  const [inputValue, SetInputValue] = useState('')

  function handleInputChange(e) {
    SetInputValue(e.target.value)
    // console.log(inputValue)
  }

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    // console.log(inputValue)
    await addToMailchimp(inputValue, {
      URL: props.url,
      TYPE: props.submitValue,
      SOURCE: 'Website'
    })
    SetInputValue('')
  }

  return (
    <form className="forms" onSubmit={handleOnSubmitForm}>
      <div className="row-form">
        <div className="form-group form-group-input">
          <input
            type="email"
            className="input-email"
            placeholder="Your email"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group form-group-submit">
          <input
            type="submit"
            className="input-submit btn-blue"
            value={inputform.submitValue}
            />
        </div>
      </div>
    </form>
  )
}

export default Forms
