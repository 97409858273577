import React from "react"
import {Link} from "gatsby"
import clsx from "clsx"

function Plan(props) {
  const {
    content,
    heading
  } = props

  function handleClick() {
    if (typeof window !== "undefined") {
      if (window.Beacon) {
        window.Beacon("toggle")
      } else {
        alert("Chat helper connection timeout. Please refresh and try again.");
      }
    }
  }

  return (
    <div className="section-plan">
      <div className="container">
        <h2 className="title text-center">{heading}</h2>
        <div className="plan-wrapper">
        {content.map((item, index) => {
            return (
              <div key={index} className="card card-non-bordered">
                <div className="card-container">
                  <div className="img-holder">
                    <img className="card-img" src={item.image} alt="Card Icon"/>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">{item.title}</h3>
                    {item.subtitle ? <h4 className="card-subtitle">{item.subtitle}</h4> : <></>}
                    {item.type === 'number' ?
                      <p className="card-text">{item.range ? <span className="range-text">from</span> : ''} ${item.price}<span className="day-text">/mo</span></p> : ' '
                    }
                    {item.type === 'perTask' ?
                      <p className="card-text">{item.range ? <span className="range-text">from</span> : ''} ${item.price}</p> : <Link to="/" className="card-text">{item.info}</Link>
                    }
                    {item.secondDesc === 'true' ?
                      <h4 className="card-subtitle">{item.secondSubtitle}</h4> : ''
                    }

                     <div className={ clsx(
                          'content-holder',
                        { 'content-reverse':item.reverse}
                      )}>
                      <ul className="list list-style-none tooltips-holder">
                        {item.desc.map((list, i) =>
                          <li key={i} className="list-item">
                            <span className={list.description ? 'list-link p-md active' : 'list-link p-md'} style={{borderBottom: list.description ? "1px dashed" : "0"}}>{list.listInfo} {list.listLogo === 'true' ? <img className="text-img" src={list.listLogoImage} alt={list.listLogoName}/>  : '' }</span>
                            {list.description ? <span className="tooltip">{list.description}</span> : ''}
                          </li>
                        )}
                      </ul>
                      {/* <button onClick={handleClick} className={item.variant === 'normal' ? "btn btn-blue" : "btn btn-outline-blue"}>{item.buttonText}</button> */}

											{item.buttonBeacon === true ? (
												<button	onClick={handleClick}	className={item.variant === 'normal' ? "btn btn-blue" : "btn btn-outline-blue"}	>	{item.buttonText}	</button>
											) : item.buttonExternal === true ? (
												<a href={item.buttonLink}	target={item.buttonNextTab === false ? '_self' : '_blank'}	rel="noopener noreferrer nofollow">	<button	className={item.variant === 'normal' ? "btn btn-blue" : "btn btn-outline-blue"}	>	{item.buttonText}	</button>	</a>
											) : (
												<Link to={item.buttonLink}> <button	className={item.variant === 'normal' ? "btn btn-blue" : "btn btn-outline-blue"}	>	{item.buttonText}	</button>{' '}	</Link>
											)}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <p class="more-info text-center p-lg">Get started with our 20-hour plan – great for quick tasks and small website projects for only $400/mo. <a href="https://calendly.com/fullstackhq/meeting" class="link-text" target="_blank">Book a call</a> or<button onClick={handleClick} className="link-text">Chat with us</button></p>
      </div>
    </div>
  )
}

export default Plan
