import React from "react"

function Works(props) {
  const {works} = props

  function handleClick() {
    if (typeof window !== "undefined") {
      if (window.Beacon) {
        window.Beacon("toggle")
      } else {
        alert("Chat helper connection timeout. Please refresh and try again.");
      }
    }
  }

  return (
    <div className="section-sample-works">
      <div className="container">
        <h2 className="text-center">Sample works</h2>
        <div className="works-wrapper">
          { works.map((item,i) => {
            return <a key={i} href={item.link} className="work-holder" target="_blank" rel="noopener noreferrer">
              <div className="link-sample"><img src={ item.image } className="img-fluid" alt="Icon Work"/></div>
            </a>
          }) }
        </div>
        <button className="btn-blue" onClick={handleClick}>Contact us to see more samples</button>
      </div>
    </div>
  )
}

export default Works
