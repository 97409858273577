import React from "react"

function Testimonials(props) {
  const {testimony} = props

  return (
    <div className="section-testimonials">
      <div className="container">
        <h2 className="testimonial-title text-center">{ props.title }</h2>
        <div className="testimonial-wrapper">
          { testimony.map((testimonial, i) => {
            return (
              <div key={ i } className="testimonial-holder">
                <p className="p-md testimonial-text">"{ testimonial.text }"</p>
                <div className="testimonial-details">
                  <img src={ testimonial.image } className="testimonial-image" alt={ testimonial.name }/>
                  <div className="testimonial-customer-detail">
                    <p className="testimonial-name">{ testimonial.name }</p>
                    <p className="testimonial-position">{ testimonial.position }</p>
                  </div>
                </div>
              </div>
            )
          }) }
        </div>
      </div>
    </div>
  )
}

export default Testimonials
