import React from "react"

function Services(props) {
  const {services} = props

  return (
    <div className="section-services">
      <div className="container">
        <div className="services-wrapper">
          <h2 className="text-center">{ props.heading }</h2>
          {props.subheading ? <p className="heading-3 text-center">{props.subheading}</p> : <></>}
          <div className="services-holder">
            { services.map((item, i) => {
              return (
                <div key={ i } className="services-item">
                  <div className="img-holder">
                   <img src={ item.image } className="img-services" alt="Icon Services"/>
                  </div>
                  <p className="p-lg text-center">{ item.desc }</p>
                </div>
              )
            }) }
          </div>
          {props.text ? <p className="p-lg text-center">{props.text}</p> : <></> }
        </div>
      </div>
    </div>
  )
}

export default Services